import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {PageLogin,PageHome,PageUserOnline} from './Pages';
import {IsLogged,GetRole} from './Utils/Function';

const Router =() =>{
    if(IsLogged()){
        return (
            <Routes>
                <Route path="/Home" element={<PageHome />} />
                <Route path="*" element={<PageHome />} />
                {(GetRole() === 99 ? <Route path="/User/Online" element={<PageUserOnline />} /> :"")}
            </Routes>
        )
    }else{
        return (
            <Routes>
                <Route path="*" element={<PageLogin />} />
            </Routes>
        )
    }
    
}

export default React.memo(Router)
import React,{useRef,useState,useEffect} from 'react';
import '../../Assets/CSS/Horapa.css';
import {DateFormat} from '../../Utils/Function';
import API from '../../API';
const PageUserOnline =()=> {

    const [UserOnlineList,setUserOnlineList] = useState([]);
    const [UpdateTime,setUpdateTime] = useState(new Date());
    useEffect(() => {
        (async () => {
            _GetUserOnline();
        })();
        
        const _Interval = setInterval(() => {
            _GetUserOnline();
        }, 60000);
        return ()=>{ 
            clearInterval(_Interval);
        }
    }, []);
    const _GetUserOnline = async()=>{
        
        API({method : "get",url:"/User/Online"}).then(res=>{
            if (res.status !== 200) {
                setUserOnlineList([]);
                return;
            }

            if (!res.data.Status) {
                setUserOnlineList([]);
                return;
            }
            setUserOnlineList(res.data.Response);
            setUpdateTime(new Date());
        }).catch(res => {
            setUserOnlineList([]);
            return;
        });
    }
    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5 className='fw-bold'>ข้อมูล UserOnline :: {DateFormat(UpdateTime,true)}</h5>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-lg-12 table-responsive'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>เวลาเข้าใช้งาน</th>
                                                    <th className='text-center'>ชื่อผู้ใช้งาน</th>
                                                    <th className='text-center'>สิทธิ์</th>
                                                    <th className='text-center'>เวลาหมดอายุ</th>
                                                    <th className='text-center'>เวลาใช้งานล่าสุด</th>
                                                    <th className='text-center'>IP Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {UserOnlineList.map((v,i)=>(
                                                    <tr key={i}>
                                                        <td className='text-center'>{DateFormat(v.LoginDate,true)}</td>
                                                        <td className='text-left'>{v.Name}</td>
                                                        <td className='text-center'>{v.Role}</td>
                                                        <td className='text-center'>{DateFormat(v.ExpireDate,true)}</td>
                                                        <td className='text-center'>{DateFormat(v.UpdateDate,true)}</td>
                                                        <td className='text-center'>{v.ClientIP}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageUserOnline)

import moment from 'moment';
export function NumberFormat(number){
    if(isNaN(number)) return 0;
    let nf = new Intl.NumberFormat('en-US');
    return nf.format(number);
} 
export function DateFormat(data,time=false){
    if(time)
        return moment(data).format("DD/MM/yyyy HH:mm")
    else
        return moment(data).format("DD/MM/yyyy")
} 

export function DateFormatJSON(data,time=false){
    return moment(data).format("yyyy-MM-DD HH:mm:ss");
} 

export function DateThaiFormat(data,time=false){
    if(time)
        return moment(data).format("DD/MM/")+(moment(data).year()+543)+ moment(data).format(" HH:mm")
    else
    return moment(data).format("DD/MM/")+(moment(data).year()+543)
} 
export function IsLogged(){
    if(sessionStorage.getItem("Authorization") === undefined) return false;
    if(sessionStorage.getItem("Authorization") === null) return false;
    if(sessionStorage.getItem("Authorization").length === 0) return false;
    return true;
}
export function MobileFormat(data){
    if(data.length == 9){
        return data.substring(0,2)+'-'+data.substring(2,6)+'-'+data.substring(6);
    }
    if(data.length == 10){
        return data.substring(0,3)+'-'+data.substring(3,6)+'-'+data.substring(6);
    }
    return data
}
export function DateThaiMonth(date){

    let _curDate = moment(date);
    let _strDate='';
    switch(_curDate.month()){
        case 0:_strDate ='มกราคม';break;
        case 1:_strDate ='กุมภาพันธ์';break;
        case 2:_strDate ='มีนาคม';break;
        case 3:_strDate ='เมษายน';break;
        case 4:_strDate ='พฤษภาคม';break;
        case 5:_strDate ='มิถุนายน';break;
        case 6:_strDate ='กรกฎาคม';break;
        case 7:_strDate ='สิงหาคม';break;
        case 8:_strDate ='กันยายน';break;
        case 9:_strDate ='ตุลาคม';break;
        case 10:_strDate ='พฤษจิกายน';break;
        case 11:_strDate ='ธันวาคม';break;
    }

    return _strDate+' '+(_curDate.year()+543);
}

export function DateThai(date){

    let _curDate = moment(date);
    let _strDate='';
    switch(_curDate.month()){
        case 0:_strDate ='มกราคม';break;
        case 1:_strDate ='กุมภาพันธ์';break;
        case 2:_strDate ='มีนาคม';break;
        case 3:_strDate ='เมษายน';break;
        case 4:_strDate ='พฤษภาคม';break;
        case 5:_strDate ='มิถุนายน';break;
        case 6:_strDate ='กรกฎาคม';break;
        case 7:_strDate ='สิงหาคม';break;
        case 8:_strDate ='กันยายน';break;
        case 9:_strDate ='ตุลาคม';break;
        case 10:_strDate ='พฤษจิกายน';break;
        case 11:_strDate ='ธันวาคม';break;
    }

    return _curDate.format('DD')+' '+ _strDate+' '+(_curDate.year()+543);
}

export function RootURL(){
    if(window.location.hostname === 'localhost') return 'http://localhost:5254'
    let _url = window.location.protocol+'//'+window.location.hostname;
    if(window.location.port.length > 0) _url = _url+':'+window.location.port;
    return _url;
}
export function GetRole(){
    if(isNaN(Number(sessionStorage.getItem('Role')))) return 0; else return Number(sessionStorage.getItem('Role'));
}
export function CleanMobile(str){
     return str.replace('-','').replace(' ','').replace('_','').trim();
}
export default{NumberFormat,MobileFormat,DateFormatJSON,DateFormat,DateThaiFormat,DateThaiMonth,DateThai,IsLogged,RootURL,GetRole,CleanMobile}